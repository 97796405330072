var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showComponent)?_c('div',{staticClass:"tw-flex tw-w-full tw-justify-center tw-items-center tw-h-full",staticStyle:{"background":"white"}},[_c('v-col',{staticClass:"tw-hidden lg:tw-flex left-side",style:({backgroundImage:("url(" + _vm.bg + ")")}),attrs:{"sm":"12","lg":"5"}},[_c('img',{staticClass:"logo",attrs:{"src":_vm.logo,"alt":"logo"},on:{"click":_vm.goHome}}),_c('h6',{staticClass:"hey-mate tw-mt-10"},[_vm._v("Hey mate!")]),_c('h6',{staticClass:"lets-set tw-py-5"},[_vm._v("Let’s Get You Set")]),_c('h6',{staticClass:"lets-set-desc"},[_vm._v("We’re excited to have you! We just need some more information.")])]),_c('v-col',{staticClass:"right-hand-side",attrs:{"sm":"12","lg":"7"}},[_c('div',{staticClass:"right-hand-side-content"},[_c('img',{staticClass:"logo-mobile tw-flex lg:tw-hidden",attrs:{"src":_vm.logo,"alt":"logo"},on:{"click":_vm.goHome}}),_c('h5',{staticClass:"right-hand-side-header"},[_vm._v("Add a Candidate")]),_c('h6',{staticClass:"right-hand-side-desc tw-py-4"},[_vm._v("Only the firstname would be visible to others")]),_c('h6',{staticClass:"right-hand-side-desc tw-py-4 tw-flex md:tw-hidden tw-self-center"},[_vm._v("Parent | Guardian")]),_c('validation-observer',{ref:"observer"},[_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"First name","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.candidate.firstname),callback:function ($$v) {_vm.$set(_vm.candidate, "firstname", $$v)},expression:"candidate.firstname"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,264734652)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Last name","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.candidate.lastname),callback:function ($$v) {_vm.$set(_vm.candidate, "lastname", $$v)},expression:"candidate.lastname"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1121106108)})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"placeholder":"Date of Birth","solo":"","hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"#F66B05"}},[_vm._v(" mdi-calendar-month-outline ")])]},proxy:true}],null,true),model:{value:(_vm.candidate.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.candidate, "dateOfBirth", $$v)},expression:"candidate.dateOfBirth"}},on))]}}],null,true),model:{value:(_vm.dateOfBirthMenu),callback:function ($$v) {_vm.dateOfBirthMenu=$$v},expression:"dateOfBirthMenu"}},[_c('v-date-picker',{attrs:{"locale":"en-in","no-title":"","max":_vm.maxDate,"color":"#F66B05"},on:{"input":function($event){_vm.dateOfBirthMenu = false}},model:{value:(_vm.candidate.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.candidate, "dateOfBirth", $$v)},expression:"candidate.dateOfBirth"}})],1),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3375461010)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Height","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-combobox',{attrs:{"color":"#F66B05","placeholder":"Height","solo":"","hide-details":"","type":"text","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","items":_vm.Heights,"required":""},on:{"change":_vm.getCity},model:{value:(_vm.candidate.height),callback:function ($$v) {_vm.$set(_vm.candidate, "height", $$v)},expression:"candidate.height"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1690331311)})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Weight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-combobox',{attrs:{"color":"#F66B05","placeholder":"Weight","append-icon":"mdi-chevron-down","solo":"","hide-details":"","type":"text","required":"","items":_vm.weights},model:{value:(_vm.candidate.weight),callback:function ($$v) {_vm.$set(_vm.candidate, "weight", $$v)},expression:"candidate.weight"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2027222906)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Shirt Size","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-combobox',{attrs:{"color":"#F66B05","placeholder":"Shirt Size","solo":"","hide-details":"","type":"text","items":_vm.shirtSizes,"append-icon":"mdi-chevron-down","required":""},model:{value:(_vm.candidate.shirtSize),callback:function ($$v) {_vm.$set(_vm.candidate, "shirtSize", $$v)},expression:"candidate.shirtSize"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,958839211)})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-combobox',{attrs:{"color":"#F66B05","placeholder":"Country","solo":"","hide-details":"","type":"text","items":_vm.countries,"append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","required":""},on:{"change":_vm.getCountiesOrState},model:{value:(_vm.candidate.address.country),callback:function ($$v) {_vm.$set(_vm.candidate.address, "country", $$v)},expression:"candidate.address.country"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2931155620)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"County Or State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-combobox',{attrs:{"color":"#F66B05","placeholder":"County Or State","solo":"","hide-details":"","type":"text","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","items":_vm.countiesOrStates,"required":""},on:{"change":_vm.getCity},model:{value:(_vm.candidate.address.county),callback:function ($$v) {_vm.$set(_vm.candidate.address, "county", $$v)},expression:"candidate.address.county"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3858310420)})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"3"}},[_c('validationProvider',{attrs:{"name":"Postal Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Postal Code","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.candidate.address.postalCode),callback:function ($$v) {_vm.$set(_vm.candidate.address, "postalCode", $$v)},expression:"candidate.address.postalCode"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2420452452)})],1),_c('v-col',{attrs:{"sm":"12","lg":"3"}},[_c('validationProvider',{attrs:{"name":"Town/City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-combobox',{attrs:{"color":"#F66B05","placeholder":"Town/City","append-icon":"mdi-chevron-down","solo":"","hide-details":"","type":"text","required":"","items":_vm.cities},model:{value:(_vm.candidate.address.city),callback:function ($$v) {_vm.$set(_vm.candidate.address, "city", $$v)},expression:"candidate.address.city"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2479008797)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"House Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"House Address","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.candidate.address.houseNumber),callback:function ($$v) {_vm.$set(_vm.candidate.address, "houseNumber", $$v)},expression:"candidate.address.houseNumber"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1433926705)})],1)],1),_c('v-row',{staticClass:"my-8 tw-flex tw-justify-between"},[_c('base-button',{attrs:{"width":"45%","button-text":"Add Another","loading":_vm.addAnotherLoading},on:{"click":_vm.addAnotherCandidate}}),_c('base-button',{attrs:{"width":"45%","button-text":"Submit","loading":_vm.loading},on:{"click":_vm.addCandidate}})],1)],1)],1)]),_c('modal',{attrs:{"dialog":_vm.showModal,"icon":_vm.check,"section":"main","title":"Great","description":"Your candidate was added successfully!.","additional-text":""},on:{"close":_vm.routeToDashboard}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }