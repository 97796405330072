<template>
<div class="tw-flex tw-w-full tw-justify-center tw-items-center tw-h-full"
     style="background: white;" v-if="showComponent">

  <v-col sm="12" lg="5" class="tw-hidden lg:tw-flex left-side" :style="{backgroundImage:`url(${bg})`}">

    <img
      :src="logo"
      alt="logo"
      class="logo"
      @click="goHome"
    />

    <h6 class="hey-mate tw-mt-10">Hey mate!</h6>

    <h6 class="lets-set tw-py-5">Let’s Get You Set</h6>

    <h6 class="lets-set-desc">We’re excited to have you! We just need some more information.</h6>
  </v-col>
  <v-col sm="12" lg="7" class="right-hand-side">
    <div class="right-hand-side-content">
    <img
      :src="logo"
      alt="logo"
      class="logo-mobile tw-flex lg:tw-hidden"
      @click="goHome"
    />
    <h5 class="right-hand-side-header">Add a Candidate</h5>
    <h6 class="right-hand-side-desc tw-py-4">Only the firstname would be visible to others</h6>

    <h6 class="right-hand-side-desc tw-py-4 tw-flex md:tw-hidden tw-self-center" >Parent | Guardian</h6>
    <validation-observer ref="observer" >

        <v-row class="mobile">
          <v-col sm="12" lg="6">
          <validationProvider
            name="First name"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="tw-pt-4 tw-mb-4" :class="classes">
              <v-text-field
                placeholder="First name"
                solo
                hide-details
                type="text"
                v-model="candidate.firstname"
                required
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </validationProvider>
          </v-col>
          <v-col sm="12" lg="6">
            <validationProvider
              name="Last name"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-text-field
                  placeholder="Last name"
                  solo
                  hide-details
                  type="text"
                  v-model="candidate.lastname"
                  required
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
        </v-row>

        <v-row class="mobile">
          <v-col sm="12" lg="6">
            <validationProvider
              name="Email"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-menu
                  v-model="dateOfBirthMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="candidate.dateOfBirth"
                      v-on="on"
                      placeholder="Date of Birth"
                      solo
                      hide-details
                      readonly
                    >
                      <template #prepend-inner>
                        <v-icon color="#F66B05" class="">
                          mdi-calendar-month-outline
                        </v-icon>
                      </template></v-text-field
                    >
                  </template>

                  <v-date-picker
                    locale="en-in"
                    v-model="candidate.dateOfBirth"
                    no-title
                    @input="dateOfBirthMenu = false"
                    :max="maxDate"
                    color="#F66B05"
                  ></v-date-picker>
                </v-menu>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
          <v-col sm="12" lg="6">
            <validationProvider
              name="Height"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-combobox
                  color="#F66B05"
                  placeholder="Height"
                  solo
                  hide-details
                  type="text"
                  append-icon="mdi-chevron-down"
                  item-text="name"
                  item-value="name"
                  :items="Heights"
                  v-model="candidate.height"
                  required
                  @change="getCity"
                ></v-combobox>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
        </v-row>

        <v-row class="mobile">

          <v-col sm="12" lg="6">
            <validationProvider
              name="Weight"
              rules="required"
              v-slot="{ classes, errors }">
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-combobox
                  color="#F66B05"
                  placeholder="Weight"
                  append-icon="mdi-chevron-down"
                  solo
                  hide-details
                  type="text"
                  v-model="candidate.weight"
                  required
                  :items="weights"
                ></v-combobox>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>


      <v-col sm="12" lg="6" >
        <validationProvider
          name="Shirt Size"
          rules="required"
          v-slot="{ classes, errors }"
        >
          <div class="tw-pt-4 tw-mb-4" :class="classes">
            <v-combobox
              color="#F66B05"
              placeholder="Shirt Size"
              solo
              hide-details
              type="text"
              v-model="candidate.shirtSize"
              :items="shirtSizes"
              append-icon="mdi-chevron-down"
              required
            ></v-combobox>
            <span>{{ errors[0] }}</span>
          </div>
        </validationProvider>
      </v-col>
        </v-row>

        <v-row class="mobile">

          <v-col sm="12" lg="6">
            <validationProvider
              name="Country"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-combobox
                  color="#F66B05"
                  placeholder="Country"
                  solo
                  hide-details
                  type="text"
                  v-model="candidate.address.country"
                  :items="countries"
                  append-icon="mdi-chevron-down"
                  item-text="name"
                  item-value="name"
                  required
                  @change="getCountiesOrState"
                ></v-combobox>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>

          <v-col sm="12" lg="6">
            <validationProvider
              name="County Or State"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-combobox
                  color="#F66B05"
                  placeholder="County Or State"
                  solo
                  hide-details
                  type="text"
                  append-icon="mdi-chevron-down"
                  item-text="name"
                  item-value="name"
                  :items="countiesOrStates"
                  v-model="candidate.address.county"
                  required
                  @change="getCity"
                ></v-combobox>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>

        </v-row>

        <v-row class="mobile">

          <v-col sm="12" lg="3">
            <validationProvider
              name="Postal Code"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-text-field
                  placeholder="Postal Code"
                  solo
                  hide-details
                  type="text"
                  v-model="candidate.address.postalCode"
                  required
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
          <v-col sm="12" lg="3">
            <validationProvider
              name="Town/City"
              rules="required"
              v-slot="{ classes, errors }">
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-combobox
                  color="#F66B05"
                  placeholder="Town/City"
                  append-icon="mdi-chevron-down"
                  solo
                  hide-details
                  type="text"
                  v-model="candidate.address.city"
                  required
                  :items="cities"
                ></v-combobox>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
          <v-col sm="12" lg="6">
            <validationProvider
              name="House Address"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-text-field
                  placeholder="House Address"
                  solo
                  hide-details
                  type="text"
                  v-model="candidate.address.houseNumber"
                  required
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
        </v-row>
        <v-row class="my-8 tw-flex tw-justify-between">
        <base-button
          width="45%"
          button-text="Add Another"
          @click="addAnotherCandidate"
          :loading="addAnotherLoading"
        ></base-button>
          <base-button
            width="45%"
            button-text="Submit"
            :loading="loading"
            @click="addCandidate"
          ></base-button>
        </v-row>

    </validation-observer>
    </div>
  </v-col>
  <modal :dialog="showModal" :icon="check" section="main" title="Great"
         description="Your candidate was added successfully!." additional-text="" @close="routeToDashboard"/>
</div>
</template>

<script>
import bg from "@/assets/bg_basketball.png"
import logo from "@/assets/logo.svg"
import BaseButton from "@/components/reuseables/BaseButton";
import {
  getAllCitiesInAState,
  getAllCountries,
  getAllStatesByCountry, guardianAddCandidate,
  uploadToCloudinary
} from "@/services/api/APIService";
import Modal from "@/components/reuseables/Modal";
import check from "@/assets/check.svg"
export default {
  name: "AddCandidate",
  components: { Modal, BaseButton },
  data(){
    return{
     bg,
      logo,
      check,
      candidate:{
       address:{}
      },
      loading: false,
      addAnotherLoading: false,
      countries:[],
      countiesOrStates:[],
      cities:[],
      cardImage :"",
      idCardImage : null,
      profile: null,
      profileImage: "",
      profileImageLoading : false,
      showModal : false,
      profileLoading: false,
      dateOfBirthMenu: false,
      maxDate: new Date(new Date().getTime() + 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10),
      Heights:["4 feet","5 feet", "5.5 feet", "6 feet", "6.5 feet", "7 feet", "7.5 feet"],
      weights:["20 Pounds","30 Pounds","50 Pounds","60 Pounds","70 Pounds","80 Pounds","90 Pounds","100 Pounds",],
      showComponent : true,
      shirtSizes:["S","M","L","XL","XXL"],
      lastCandidate: false
    }
  },
  computed:{
    preset() {
      return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;
    },
    validData(){
      return this.candidate.firstname && this.candidate.lastname
        && this.candidate.dateOfBirth && this.candidate.address.postalCode
         && this.candidate.address.country
       && this.candidate.address.county && this.candidate.address.houseNumber
      && this.candidate.shirtSize
    }
  },
  methods:{
    routeToDashboard(){
      this.$router.push({name : 'MainDashboard'})
    },
    onLogoFile() {
      this.$refs.idImage.click();
    },
    async onLogoImagePicked(event){
      const files = event.target.files[0]
      try {
        const fileReader =  new FileReader()
        fileReader.addEventListener('load', async () => {
          this.recoveredImg = ""
          this.idCardImage = fileReader.result
          this.cardImage = files.name
        })
        fileReader.readAsDataURL(files)
      } catch (err) {
        console.log(err.response)
      }

    },

    onProfileImageFile() {
      this.$refs.profileImage.click();
    },
    async onProfileImagePicked(event) {
      const files = event.target.files[0];
      try {
        this.profileImageLoading = true;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          this.recoveredImg = "";
          this.profile = fileReader.result;
          this.profileImage = files.name;
          let newData = {
            file: this.profile,
            upload_preset: this.preset,
          };
          const response = await uploadToCloudinary(newData);
          console.log(response);
          this.candidate.profileImage = response.data.url;
          this.profileImageLoading = false;
        });
        fileReader.readAsDataURL(files);
      } catch (err) {
        console.log(err.response);
      }
    },
    goHome() {

      this.$router.push({name:"HomePage"})
    },
   async handleAddCandidate(){

       if (this.validData){
        let parentData = JSON.parse(sessionStorage.getItem("parentData"))
        this.candidate.guardianId = parentData.id
        // let uploadData = { file: this.idCardImage, upload_preset: this.preset }
        // const response = await uploadToCloudinary(uploadData)
        // this.candidate.idCard = response.data.url
        if (this.candidate.address.country.name) {
          this.candidate.address.country = this.candidate.address.country.name
        }
        if (this.candidate.address.county.name) {
          this.candidate.address.county = this.candidate.address.county.name
        }
        if (this.candidate.address.city && this.candidate.address.city.name) {
          this.candidate.address.city = this.candidate.address.city.name
        }
        guardianAddCandidate(this.candidate).then(res => {
          console.log(res.data)
          this.loading = false
          this.addAnotherLoading = false
          if (this.lastCandidate){
            this.showModal = true
          }

        }).catch((err) => {
          this.loading = false
          this.addAnotherLoading = false
          console.log(err, "in compo err");
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          })
        })
      }
      else {
         this.showModal = false
        this.loading=false
         this.addAnotherLoading = false

        this.$displaySnackbar({
          message: "Empty field(s) not allowed",
          success: false,
        })
      }
    },
    async getCountries() {
      try {
        const response = await getAllCountries()
        this.countries = response.data.data
      } catch(err) {
        console.log(err.response)
      }
    },
    async getCountiesOrState() {
      try {
        let data = {
          country: this.candidate.address.country
        }
        if (this.candidate.address.country.name){
          data.country = this.candidate.address.country.name
        }
        const response = await getAllStatesByCountry(data)
        this.countiesOrStates = response.data.data.states

      } catch(err) {
        console.log(err.response)
      }
    },
    async getCity() {
        try {
          let data = {
            country: this.candidate.address.country,
            state: this.candidate.address.county
          };
          if (this.candidate.address.country.name){
            data.country = this.candidate.address.country.name;
          }
          if (this.candidate.address.county.name){
            data.state = this.candidate.address.county.name
          }
          console.log(data)
          const response = await getAllCitiesInAState(data);
          console.log(response);
          this.cities = response.data.data;

        } catch (err) {
          console.log(err.response);
          this.cities = [];
        }

    },
   async addAnotherCandidate(){
      this.addAnotherLoading = true
     await this.handleAddCandidate().then(()=>{
       window.location.reload()
     })
    },
    async addCandidate(){
      this.loading = true
      this.lastCandidate = true
      await this.handleAddCandidate().then(()=>{

      })


    },

  },
  mounted() {
    this.getCountries()
  },
  created() {


  }
};
</script>

<style scoped lang="scss">

.right-hand-side{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 7rem;
  min-height: 100vh;

  @media screen and (max-width: 1024px) {
    padding: 1.5rem;

  }

}


.right-hand-side-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #F66B05;
  padding-top: 5rem;

  @media screen and(max-width: 1024px) {
    padding-top: 0;
  }
}
.right-hand-side-desc{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  color: #828282;
}
.upload{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 35px;
  color: #F66B05;
  text-transform: none;
}

.left-side {
  display: flex;
  width: 100%;
  min-height: available;
  height: 100% !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  padding: 5rem 7rem !important;
  justify-content: flex-start;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.hey-mate{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 0;
  color: #FFFFFF;
}

.logo{
  height: auto;
  width: 241px;
  cursor: pointer;
  margin-left: -1rem;
}

.logo-mobile{
  height: auto;
  width: 157px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.lets-set{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0;
  color: #FFFFFF;
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
}

.lets-set-desc{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  width: 40%;
}

.mobile{
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 10px;
  align-items: center;
  height: 80px;
  width: 80px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.button-logo-input .button-logo-input-img {
  width: 24.19px;
  height: 18.72px;
}
</style>